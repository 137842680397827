import React, { useState, useEffect } from "react"
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import "./plan_form.scss"
import { useForm } from "react-hook-form"
import { useGlobalState } from "../contexts/global_state"
import { PaymentForm, PaymentReceipt } from "../interfaces/payment"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { addPaymentReciept } from "../utils/fire"
import CheckoutForm from "./checkoutForm"
export default function PlanForm() {
  const [clientToken, setClientToken] = useState<string | null>()
  const { register, handleSubmit } = useForm()
  const [roofLayoutFile, setRoofLayoutFile] = useState<any>()
  const [image, setImage] = useState<any>()
  const [state, dispatch] = useGlobalState()

  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const price = state.permitPlan?.price;
  async function setToken() {
    const response = await fetch(
      "https://us-central1-tapl-solar.cloudfunctions.net/braintreeFunction/getToken"
    )
    const responseJSON = await response.json()
    const clientToken2 = responseJSON.clientToken
    if (clientToken2) setClientToken(clientToken2)
  }
  async function onSuccess() {
    if (state.paymentForm && price ) {
      const paymentReceipt: PaymentReceipt = {
        paymentForm: state.paymentForm,
        amountPaid: price,
        date:new Date().toISOString(),
        cart: [state.permitPlan],
      }
      await addPaymentReciept(paymentReceipt)
    }
    navigate('/payment-success')
  }
  useEffect(() => {
    if(!state.permitPlan){
      navigate("/permit-plans")
      alert("No permit plan selected!");
    }
    setToken()
  }, [])
  function onImageChange(e: any) {
    const file = e.target.files[0]
    if (!file.type.startsWith("image/")) {
      alert("Kindly provide a Image file")
      return
    }
    setImage(file)
  }

  function onSubmit(data: any) {
    const tempForm: PaymentForm = {
      name: data.customerName,
      address: data.address,
    }
    dispatch({type:"SET_PAYMENT_FORM",payload:tempForm})
    setShowPaymentModal(true)
  }
  return (
    <div className="project-form-page">
      <Modal
        centered
        onHide={() => setShowPaymentModal(false)}
        show={showPaymentModal}
      >
        <Modal.Header closeButton>Payment Method</Modal.Header>
        <Modal.Body>
          {clientToken && state.permitPlan && (
            <CheckoutForm
              paymentOptions={{
                clientToken,
                price,
              }}
              onSuccessfulPayment={onSuccess}
              active={true}
            />
          )}
        </Modal.Body>
      </Modal>

      <div className="page-heading-container">
        <div className="page-heading">Project Form</div>
        <div className="page-subheading">
          Please fill the details below so we can get in contact with you
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                {...register("customerName")}
                placeholder="Enter your name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                {...register("address")}
                as="textarea"
                placeholder="Enter your name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Roof Layout</Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={true}
                  value={roofLayoutFile ? roofLayoutFile.name : ""}
                  placeholder={"Upload roof layout if you have any"}
                />
                <InputGroup.Append>
                  <Button
                    onClick={() =>
                      document.getElementById("roofLayoutInput")?.click()
                    }
                    className="form-button"
                  >
                    Browse
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <input
                onChange={(e: any) => setRoofLayoutFile(e.target.files[0])}
                className="d-none"
                type="file"
                id="roofLayoutInput"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Clear photos of your electrical panel</Form.Label>
              <div
                className="d-flex align-items-center justify-content-center pointer-on-hover"
                style={{
                  backgroundColor: "#F4F5F8",
                  height: 109,
                  width: 92,
                }}
                onClick={() => document.getElementById("choose_pp")?.click()}
              >
                {image && (
                  <img
                    alt="image"
                    height={109}
                    width={92}
                    src={URL.createObjectURL(image)}
                  />
                )}
                <StaticImage
                  src="../images/plus.png"
                  height={27}
                  width={27}
                  alt="plus"
                  placeholder="blurred"
                />
                <input
                  id="choose_pp"
                  style={{
                    display: "none",
                  }}
                  type="file"
                  accept="image/*"
                  onChange={onImageChange}
                />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Main pannel beaker rating</Form.Label>
              <Form.Control placeholder="Enter Main pannel beaker rating" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Roof type</Form.Label>
              <Form.Control placeholder="Enter your roof type" />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Solar panel</Form.Label>
              <div className="d-flex">
                <Form.Control placeholder="Brand" className="m-2 text-muted"/>
                <Form.Control placeholder="Model" className="m-2 text-muted"/>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Inverter</Form.Label>
              <div className="d-flex">
                <Form.Control placeholder="Brand" className="m-2 text-muted"/>
                <Form.Control placeholder="Model" className="m-2 text-muted"/>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Racking</Form.Label>
              <div className="d-flex">
                <Form.Control placeholder="Brand" className="m-2 text-muted"/>
                <Form.Control placeholder="Model" className="m-2 text-muted"/>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Additional Information</Form.Label>
              <Form.Control
                {...register("additionalInformation")}
                as="textarea"
                placeholder="Enter your name"
              />
            </Form.Group>
            <Form.Group>
              <Button className="form-button btn-block" type="submit">
                Submit
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
