import React from 'react'
import NavigationBar from '../components/navigation_bar'
import PlanForm from '../components/plan_form'
import SEO from '../components/seo';

export default function Test() {
    return (
        <div>
            <NavigationBar current="/permit-plans" />
            <SEO title="Buy Permit Plan"/>
            <PlanForm/>
        </div>
    )
}
